import React from "react";
import { SquordleColors } from "../../styles/squordleStyles";
import GameMaster, { GAME_MODE } from "../objects/GameMaster";

export default class ReplayView extends React.Component {

    render() {

        const show = this.props.show
        const clickNewGame = this.props.onClickNewGame

        if (show) return (
            <div style={{
                width: '100%',
                height: 'calc(100% - 50px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                zIndex: 1000,
                top: 50,
                backgroundColor: SquordleColors.squordleLightDimView
            }}>
                <button onClick={() => clickNewGame()} style={{
                    backgroundColor: SquordleColors.squordleBlue,
                    borderWidth: 0,
                    height: 70,
                    width: 210,
                    fontSize: '2vh',
                    color: 'white',
                    borderRadius: 4,
                    marginBottom: '5%',
                    cursor: 'pointer'
                }}>NEW GAME!</button>
            </div>
        )
        else return null

    }

}
import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import { puzzlesCollection } from './utils/firebase'
import HomeScreen from './screens/HomeScreen';
import LoadingScreen from './screens/LoadingScreen';
import Game from './screens/Game'
import Puzzle from './components/objects/Puzzle'
import GameMaster, { GAME_MODE } from './components/objects/GameMaster';
import memory from './utils/memory';

function App() {

    const [isLoading, setLoading] = useState(true)

    useEffect(() => {

        // Load todays puzzle information
        let fullDate = new Date()
        let timezoneOffset = fullDate.getTimezoneOffset()
        fullDate.setMinutes(fullDate.getMinutes() - timezoneOffset)
        let date = fullDate.toJSON().slice(0, 10)

        puzzlesCollection().where('date', '==', date)
            .get()
            .then(querySnapshot => {

                const puzzle = Puzzle.getInstance()
                const doc = querySnapshot.docs[0]

                puzzle.setPuzzleData(doc.data())
                const m = memory.getInstance().loadGameMode()
                if (m === GAME_MODE.DAILY) GameMaster.getInstance().initForCurrentPuzzle()
                else GameMaster.getInstance().initForPracticePuzzle()

                setTimeout(() => {
                    setLoading(false)
                }, 500);

            }).catch(err => {
                console.log('error fetching puzzle info: ', err);
                setLoading(false)
            })

    })

    if (isLoading) {
        return <LoadingScreen />
    } else if (Puzzle.getInstance().date !== undefined) {
        // return <HomeScreen />
        return <Game />
    } else return (
        <div className="App">
            <header className="App-header">
                <p style={{ fontFamily: 'Monaco', color: '#333333', fontSize: 18 }}>
                    Uh-oh! Something seems to have gone wrong!
                </p>
                <p style={{ fontFamily: 'Monaco', color: '#333333', fontSize: 15 }}>
                    Come back later!
                </p>
            </header>
        </div>
    );
}

export default App;

export const WHIFF_STATE = {
    MISS: 1,
    REVEAL: 2
}

export default class WhiffGuess {

    value = ''
    state = WHIFF_STATE.MISS


    /**
 * @returns {WhiffGuess}
 */

    static whiffGuess(value, state) {

        let whiffGuess = new WhiffGuess()

        whiffGuess.value = value
        whiffGuess.state = state

        return whiffGuess

    }

}
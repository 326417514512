import React from "react";
import ShareLogo from '../../assets/share-icon-ios-white.svg';
import { SquordleColors } from "../../styles/squordleStyles";

export default class ShareView extends React.Component {

    render() {

        let shareClick = this.props.didClickShare

        return (
            <div style={{
                flex: 0.5,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <button onClick={() => shareClick()} style={styles.button}>
                    <p style={{
                        margin: 0,
                        paddingLeft: '5%',
                        alignSelf: 'center',
                        textAlign: 'right',
                        fontSize: '2.5vh',
                        color: 'white'
                    }}>SHARE</p>
                    <img alt='' src={ShareLogo} draggable='false' style={{
                        alignSelf: 'center',
                        height: '40%',
                        width: '25%',
                    }}></img>
                </button>
            </div>
        )

    }

}

const styles = {
    button: {
        backgroundColor: SquordleColors.squordleGreen,
        width: '75%',
        display: 'flex',
        height: '75%',
        borderWidth: 0,
        borderRadius: 4,
        flexDirection: 'row',
        cursor: 'pointer',
        alignContent: 'center',
        justifyContent: 'center'
    }
}

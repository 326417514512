import React, { Component } from "react";
import memory from "../../utils/memory";
import StatsCell from "./StatCell";

export default class StatsView extends Component {

    render() {

        const stats = memory.getInstance().stats().s

        return (
            <div style={{
                flex: 0.2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 'auto'
            }}>
                <StatsCell value={stats.played} label={'played'} />
                <StatsCell value={stats.winP} label={'win %'} />
                <StatsCell value={stats.streak} label={'streak'} />
                <StatsCell value={stats.max} label={'max'} />
            </div>
        )

    }

}
export default class Puzzle {

    static myInstance = null;

    puzzleNumber = 0
    date = ''
    difficulty = 1
    hint = false
    hintData = {}
    revealLetters = []
    words = []


    /**
 * @returns {Puzzle}
 */
    static getInstance() {
        if (Puzzle.myInstance == null) {
            Puzzle.myInstance = new Puzzle();
        }
        return this.myInstance;
    }

    setPuzzleData(data) {
        this.date = data.date
        this.difficulty = data.difficulty
        this.hint = data.hint
        this.hintData = this.hint ? data.hintData : {}
        this.revealLetters = data.revealLetters
        this.words = data.words
        this.puzzleNumber = data.puzzleNumber ? data.puzzleNumber : 0
    }


    static puzzleForData(data) {

        let puzzle = new Puzzle()

        puzzle.date = data.date
        puzzle.difficulty = data.difficulty
        puzzle.hint = data.hint
        puzzle.hintData = puzzle.hint ? data.hintData : {}
        puzzle.revealLetters = data.revealLetters
        puzzle.words = data.words

        return puzzle
    }

}

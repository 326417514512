import React from "react";
import Keyboard from '../components/Keyboard'
import Header from "../components/Header";
import Input from "../components/Input";
import Board from "../components/Board";
import Misses from "../components/Misses";
import Letters from "../components/Letters";
import GameMaster, { GAME_MODE, inputLIsValidG, keyInputIsE } from "../components/objects/GameMaster";
import Alert from "../components/Alert";
import styles from '../styles/gameStyles'
import GameResultAlert from "../components/GameResultAlert";
import GameSummary from "../components/GameSummary";
import { device_info, s_content } from "../components/objects/Sharer";
import Rules from "./Rules";
import Donate from "./Donate";
import memory from "../utils/memory";
import RulesSummary from "./RulesSummary";
import Menu from "./Menu";
import ReplayView from "../components/custom/ReplayView";
// import words from '../assets/final_word_list_4_18-2.json'
// import { puzzlesCollection } from "../utils/firebase";


let game = GameMaster.getInstance()


export default class Game extends React.Component {

    constructor() {
        super()

        game.setCallbacks((d) => this.gameFinished(d), (m) => this.gameAlert(m))

        this.state = {
            currentInput: '',
            alertMessages: [],
            letterGuesses: [],
            whiffs: [],
            boardSquares: [],
            pendingLetters: [],
            burnedLetters: [],
            boardUpdates: [],
            letterUpdates: [],
            whiffUpdates: [],
            wordAnimation: game.wordAnimation,
            animateForLoad: [],
            showMenu: false,
            gameMode: game.mode,
            validInput: true,
            gameEnabled: true,
            showReplay: false,
            showResultAlertMessage: false,
            showGameSummary: false,
            showRulesSummary: false,
            showRules: false,
            showDonate: false,
            showStats: false,
            showSettings: false,
        }
    }

    componentDidMount() {

        // Makes right clicking disabled
        document.addEventListener('contextmenu', event => event.preventDefault())

        this.keyPress = this.keyPress.bind(this)
        this.handleKeyboardListening(true)

        this.setState({
            boardSquares: game.boardSquares,
            letterGuesses: game.letterSquares,
            pendingLetters: game.pendingLetters,
            burnedLetters: game.burnedLetters,
            whiffs: game.whiffs,
            initialAnimations: game.initialAnimations,
        })

        if (!memory.getInstance().didShowRulesOnFirstLoad()) {
            this.handleKeyboardListening(false)
            this.setState({ gameEnabled: false })
            setTimeout(() => {
                this.setState({ showRules: true, gameEnabled: true })
            }, 2000);
        }

        if (game.isCompleted()) {
            setTimeout(() => {
                this.gameFinished(0)
            }, 1500);
        }

        // this.create_games()

    }



    // create_games() {

    //     words.forEach(w => {
    //         puzzlesCollection().add(w).then(result => {
    //             console.log('added doc!: ', w.puzzleNumber);
    //         }).catch(err => console.log('error with ', w.puzzleNumber, ': ', err))
    //     })

    // }



    didSelectGameMode(m) {

        let didChange = game.updateForMode(m)
        this.setState({ gameMode: game.mode, showMenu: false })
        this.handleKeyboardListening(true)
        if (didChange) {
            this.setState({
                boardSquares: game.boardSquares,
                letterGuesses: game.letterSquares,
                pendingLetters: game.pendingLetters,
                burnedLetters: game.burnedLetters,
                whiffs: game.whiffs,
                initialAnimations: game.initialAnimations,
                showReplay: game.isCompleted() && !game.isDaily()
            })
        }
    }



    playNewPracticeGame() {
        game.initForPracticePuzzle(true)
        this.setState({
            boardSquares: game.boardSquares,
            letterGuesses: game.letterSquares,
            pendingLetters: game.pendingLetters,
            burnedLetters: game.burnedLetters,
            whiffs: game.whiffs,
            initialAnimations: game.initialAnimations,
            showReplay: game.isCompleted() && !game.isDaily()
        })
    }



    gameFinished(d) {

        // Remove key listener
        this.handleKeyboardListening(false)
        this.setState({ letterGuesses: game.letterSquares })
        setTimeout(() => {
            this.setState({ showResultAlertMessage: true })
        }, d);

        setTimeout(() => {
            this.setState({
                showResultAlertMessage: false,
            })
        }, game.didWin() ? 3000 : 4000);

    }


    gameAlert(m) {
        this.showAlertMessage(m)
    }


    keyPress(e) {

        if (game.isCompleted()) return

        let key = e.key.toUpperCase()

        if (!/^[a-zA-Z]+$/.test(key)) return

        // Update the input field
        game.updateForUserInput(key)
        this.setState({ initialAnimations: [], currentInput: game.currentInput, validInput: game.inputIsValid })

        if (keyInputIsE(key)) {
            if (inputLIsValidG(this.state.currentInput) && game.inputIsValid && !game.letterWasGuessed(this.state.currentInput)) {
                game.didSubmitGuess()
                this.setState({
                    currentInput: game.currentInput,
                    letterGuesses: game.letterSquares,
                    pendingLetters: game.pendingLetters,
                    burnedLetters: game.burnedLetters,
                    boardSquares: game.boardSquares,
                    whiffs: game.whiffs,
                    boardUpdates: game.boardUpdates,
                    whiffUpdates: game.whiffUpdates,
                    letterUpdates: game.letterUpdates,
                    wordAnimation: game.wordAnimation
                })
            } else {
                this.showAlertMessage(game.getAlertMessage())
                game.manuallySetCurrentInput('')
                this.setState({ currentInput: game.currentInput })
            }
        }

    }


    showAlertMessage(msg) {

        let alert = {
            key: msg,
            html: <Alert key={msg} message={msg} />
        }

        let currentAlerts = this.state.alertMessages
        currentAlerts.unshift(alert.html)

        this.setState({ alertMessages: currentAlerts })

        setTimeout(() => {
            currentAlerts.pop()
            this.setState({ alertMessages: currentAlerts })
        }, 2000);

    }



    // Keyboard listening
    handleKeyboardListening(listen) {
        if (listen) document.addEventListener('keydown', this.keyPress, true)
        else document.removeEventListener('keydown', this.keyPress, true)
    }



    render() {

        return (
            <div style={{
                ...styles.screen,
                pointerEvents: this.state.gameEnabled ? 'all' : 'none',
            }}>
                <ReplayView show={this.state.showReplay} onClickNewGame={() => this.playNewPracticeGame()}></ReplayView>
                {device_info().m ? <Menu show={this.state.showMenu} mode={this.state.gameMode} clickDaily={() => {
                    this.didSelectGameMode(GAME_MODE.DAILY)
                }} clickPractice={() => {
                    this.didSelectGameMode(GAME_MODE.PRACTICE)
                }}></Menu> : null}
                <RulesSummary show={this.state.showRulesSummary} onClose={() => {
                    this.handleKeyboardListening(true)
                    this.setState({ showRulesSummary: false })
                }} />
                <Rules show={this.state.showRules} onClose={() => {
                    this.handleKeyboardListening(true)
                    this.setState({ showRules: false })
                }} />
                <Donate show={this.state.showDonate} onClose={() => {
                    this.handleKeyboardListening(true)
                    this.setState({ showDonate: false })
                }} />
                <GameResultAlert message={game.getResultAlertMessage()} score={game.getScore()} result={game.state} show={this.state.showResultAlertMessage} onExit={() => {
                    this.setState({ showGameSummary: true })
                }} />
                <GameSummary show={this.state.showGameSummary} onClose={() => {
                    this.handleKeyboardListening(!game.isCompleted())
                    this.setState({
                        showGameSummary: false,
                        showReplay: game.isCompleted() && !game.isDaily()
                    })
                }} onShare={() => {
                    let s = s_content()
                    if (s.c) this.gameAlert('Copied to clipboard!')
                }} />
                <div id="alertContainer" style={styles.alertContainer}>
                    {this.state.alertMessages}
                </div>
                <Header mode={this.state.gameMode} showingMenu={this.state.showMenu} onClickRules={() => {
                    this.handleKeyboardListening(false)
                    this.setState({ showMenu: false })
                    this.setState({ showRules: true })
                }} onClickDonate={() => {
                    this.handleKeyboardListening(false)
                    this.setState({ showMenu: false })
                    this.setState({ showDonate: true })
                }} onClickStats={() => {
                    this.handleKeyboardListening(false)
                    this.setState({ showMenu: false })
                    this.setState({ showGameSummary: true })
                }} onClickDropDown={() => {
                    this.handleKeyboardListening(false)
                    this.setState({ showMenu: !this.state.showMenu })
                }} onClickOption={(option) => {
                    this.didSelectGameMode(option === 'daily' ? GAME_MODE.DAILY : GAME_MODE.PRACTICE)
                }} />
                <div style={styles.flexContainer}>
                    <div style={styles.container}>
                        <div style={styles.gameArea}>
                            <div style={styles.boardWhiffArea}>
                                <Board squares={this.state.boardSquares} newSquares={this.state.boardUpdates} wordAnimation={this.state.wordAnimation} initialAnimations={this.state.initialAnimations} />
                                <Misses whiffs={this.state.whiffs} newWhiffs={this.state.whiffUpdates} />
                            </div>
                            <div style={styles.lettersInputArea}>
                                <Letters letters={this.state.letterGuesses} newLetters={this.state.letterUpdates} />
                                <Input input={this.state.currentInput} validInput={this.state.validInput} />
                            </div>
                        </div>
                        <Keyboard letterGuesses={this.state.letterGuesses} pendingLetters={this.state.pendingLetters} burnedLetters={this.state.burnedLetters} onKeyPress={(e) => {
                            this.keyPress(e)
                        }} />
                    </div>
                </div>

            </div >
        )

    }

}


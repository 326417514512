import React, { Component } from "react";
import SquordleLogo from '../assets/squordle-logo.svg';

export default class LoadingScreen extends Component {

    render() {

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
            }}>
                <img alt="" src={SquordleLogo} draggable='false' style={{
                    height: '10vh',
                    width: '10vh',
                    margin: 0,
                }}></img>
            </div>
        )

    }

}
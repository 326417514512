import React from "react";
import '../../styles/animations.css'
import { SquordleColors } from "../../styles/squordleStyles";


export default class GridSquare extends React.Component {

    render() {

        const letter = this.props.letter
        const style = this.props.style
        const animation = this.props.animation
        const miss = this.props.miss ? this.props.miss : false
        const isRL = this.props.isRL
        const a = animation.a
        const d = animation.d
        let w = animation.w
        if (w === undefined) w = { a: false, r: 0 }

        function RL() {
            return <div style={{
                height: 10,
                borderTopWidth: '1.5vh',
                borderTopStyle: 'solid',
                borderTopColor: SquordleColors.squordleLightBlue,
                borderRightStyle: 'solid',
                borderRightWidth: '1.5vh',
                borderRightColor: 'transparent',
                backgroundColor: 'transparent',
                width: 0,
                margin: 0,
                top: 0,
                left: 0,
                position: 'absolute',
            }}></div>
        }

        let aStr = '400ms cubic-bezier(.04, .58, .32, 1) ' + d + 'ms  1 keyBounceEnter'
        if (miss) aStr = '400ms cubic-bezier(.04, .58, .32, 1) ' + d + 'ms  1 keyBounceMiss'


        if (w.a === true && a) return (
            <div style={{
                ...style,
                animation: '500ms cubic-bezier(.04, .58, .32, 1) ' + d + 'ms  1 keyBounceWord'
            }}>{letter}{isRL ? RL() : null}</div>
        )
        else {
            return (
                <div style={{
                    ...style,
                    // backgroundColor: w.a ? SquordleColors.squordleGold : style.backgroundColor,
                    animation: a ? aStr : '',
                }}>{letter}{isRL ? RL() : null}</div>
            )
        }

    }

}
import React from "react";
import { SquordleColors } from "../styles/squordleStyles";
import SquordleLogo from '../assets/squordle-logo.svg';
import QuestionMark from '../assets/question-mark-icon.svg';
import HeartIcon from '../assets/heart-icon.svg';
import Stats from '../assets/rankings-icon.svg';
import DownArrow from '../assets/down-arrow-white-icon.svg';
import UpArrow from '../assets/up-arrow-white-icon.svg';
import { device_info } from "./objects/Sharer";
import { GAME_MODE } from "./objects/GameMaster";
// import Settings from '../assets/settings-icon.svg';

export default class Header extends React.Component {

    didClickDropDownMenu() {
        this.props.onClickDropDown()
    }


    render() {

        const onClickRules = this.props.onClickRules
        const onClickDonate = this.props.onClickDonate
        const onClickStats = this.props.onClickStats
        const onClickOption = this.props.onClickOption

        const mode = this.props.mode
        const is_d = mode === GAME_MODE.DAILY
        const showingMenu = this.props.showingMenu

        let dailyColor = is_d ? SquordleColors.squordleGreen : 'transparent'
        let practiceColor = !is_d ? SquordleColors.squordleGreen : 'transparent'
        let dropDownTitle = is_d ? 'DAILY' : 'PRACTICE'

        const condensed = device_info().m

        if (!condensed) {
            return (<div style={styles.header}>
                <div style={{
                    flex: 1,
                    paddingLeft: 5,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    {condensed ? null
                        :
                        <button onClick={() => onClickRules()} style={styles.button}>
                            <img alt='' src={QuestionMark} draggable='false' style={{
                                height: '60%', width: '3.3vh', margin: 'auto',
                            }}></img>
                        </button>}
                    <button onClick={() => onClickOption('daily')} style={{ fontSize: '85%', height: '70%', flex: 1, maxWidth: 70, borderRadius: 4, borderWidth: 0, marginRight: 10, marginLeft: 10, backgroundColor: dailyColor, color: is_d ? 'white' : 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>DAILY</button>
                    <button onClick={() => onClickOption('practice')} style={{ fontSize: '85%', height: '70%', flex: 1, maxWidth: 90, borderRadius: 4, borderWidth: 0, marginRight: 10, marginLeft: 10, backgroundColor: practiceColor, color: !is_d ? 'white' : 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>PRACTICE</button>
                </div>
                <div style={styles.centerContainer}>
                    <img alt='Squordle' src={SquordleLogo} draggable='false' style={{
                        height: condensed ? 45 * 0.7 : 50 * 0.7,
                        width: condensed ? 45 * 0.7 : 50 * 0.7,
                        margin: 0,
                    }}></img>
                    <h1 style={{ margin: 0, paddingLeft: 10, fontSize: '3vh', fontWeight: 800, fontFamily: 'Times', letterSpacing: -0.5 }}>Squordle</h1>
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    paddingRight: 5
                }}>
                    {condensed ? <button onClick={() => onClickRules()} style={styles.button}>
                        <img alt='' src={QuestionMark} draggable='false' style={{
                            height: '60%', width: '3.3vh', margin: 'auto',
                        }}></img>
                    </button> : null}
                    <button onClick={() => onClickDonate()} style={styles.button}>
                        <img alt='' src={HeartIcon} draggable='false' style={{
                            height: '60%',
                            width: '3.3vh',
                            margin: 'auto',
                        }}></img>
                    </button>
                    <button onClick={() => onClickStats()} style={styles.button}>
                        <img alt='' src={Stats} draggable='false' style={{
                            height: '50%',
                            width: '3vh',
                            margin: 'auto',
                        }}></img>
                    </button>
                </div>
            </div>)
        } else return (
            <div style={styles.header}>
                <div style={{ ...styles.centerContainer, marginLeft: 10 }}>
                    <img alt='Squordle' src={SquordleLogo} draggable='false' style={{
                        height: condensed ? 45 * 0.7 : 50 * 0.7,
                        width: condensed ? 45 * 0.7 : 50 * 0.7,
                        margin: 0,
                    }}></img>
                    <h1 style={{ margin: 0, paddingLeft: 10, fontSize: '3vh', fontWeight: 800, fontFamily: 'Times', letterSpacing: -0.4 }}>Squordle</h1>
                </div>
                <div style={{
                    flex: 1,
                    paddingLeft: 5,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    {condensed ? null
                        :
                        <button onClick={() => onClickRules()} style={styles.button}>
                            <img alt='' src={QuestionMark} draggable='false' style={{
                                height: '60%', width: '3.3vh', margin: 'auto',
                            }}></img>
                        </button>}
                    <button onClick={() => this.didClickDropDownMenu()} style={{ fontSize: '81%', height: '70%', flex: 1, maxWidth: 'fit-content', borderRadius: 4, borderWidth: 0, marginRight: 10, marginLeft: 10, width: 'auto', backgroundColor: SquordleColors.squordleGreen, color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', padding: 8 }}>{dropDownTitle}
                        <img alt='' src={showingMenu ? UpArrow : DownArrow} draggable='false' style={{
                            height: '55%', width: 15, paddingLeft: 7,
                        }}></img>
                    </button>
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    paddingRight: 5
                }}>
                    {condensed ? <button onClick={() => onClickRules()} style={styles.button}>
                        <img alt='' src={QuestionMark} draggable='false' style={{
                            height: '60%', width: '3.3vh', margin: 'auto',
                        }}></img>
                    </button> : null}
                    <button onClick={() => onClickDonate()} style={styles.button}>
                        <img alt='' src={HeartIcon} draggable='false' style={{
                            height: '60%',
                            width: '3.3vh',
                            margin: 'auto',
                        }}></img>
                    </button>
                    <button onClick={() => onClickStats()} style={styles.button}>
                        <img alt='' src={Stats} draggable='false' style={{
                            height: '50%',
                            width: '3vh',
                            margin: 'auto',
                        }}></img>
                    </button>
                </div>
            </div>)

    }

}


const styles = {
    header: {
        backgroundColor: SquordleColors.squordleVeryLightGray,
        width: '100%',
        height: 45,
        minHeight: 45,
        display: 'flex',
        flexDirection: 'row',
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none'
    },
    centerContainer: {
        display: 'flex',
        flex: 'min-content',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        backgroundColor: 'transparent',
        width: '4.7vh',
        display: 'flex',
        borderWidth: 0,
        borderRadius: 4,
        cursor: 'pointer',
        alignContent: 'center',
        justifyContent: 'center'
    }
}


import React, { Component } from "react";
import { SquordleColors } from "../styles/squordleStyles";
import CloseIcon from '../assets/close-icon.svg';
import HeartIcon from '../assets/heart-filled-icon.svg';
import { CSSTransition } from "react-transition-group";
import { FirebaseEvent } from "../utils/firebase";


export default class Donate extends Component {

    render() {

        let show = this.props.show
        const closeDonate = this.props.onClose

        return (
            <CSSTransition
                // nodeRef={nodeRef}
                in={show}
                timeout={300}
                classNames="alert"
                unmountOnExit
            // onExited={() => onExit()}
            >
                <div style={styles.container}>
                    <div style={{
                        height: 'auto',
                        width: '100%',
                        position: 'relative',
                        maxWidth: 600
                    }}>
                        <div style={{
                            height: 80,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}>
                            <p style={{
                                textAlign: 'center',
                                fontSize: '3vh',
                                margin: 0,
                                flex: 1,
                                paddingLeft: 70
                            }}>DONATE</p>
                            <button onClick={() => closeDonate()} style={styles.button}>
                                <img alt='' src={CloseIcon} draggable='false' style={styles.icon}></img>
                            </button>
                        </div>
                        <div style={{
                            width: '100%',
                            height: 'auto',
                            fontWeight: 400,
                        }}>
                            <div style={{
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                marginBottom: 10,
                                marginRight: 20,
                                marginLeft: 20
                            }}>
                                <p style={{ marginBottom: 20 }}><b>Love our game? Support the cause and grab us a coffee!</b></p>
                                <button onClick={() => {
                                    FirebaseEvent('clickedDonate', {})
                                    window.open('https://www.buymeacoffee.com/squordle', '_blank')
                                }} style={{
                                    height: 50,
                                    marginTop: 10,
                                    width: '50%',
                                    borderWidth: 0,
                                    borderRadius: 4,
                                    backgroundColor: SquordleColors.squordleGold,
                                    color: 'white',
                                    fontSize: '2vh',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    boxShadow: '0px 5px 60px #D5C370'
                                }}>DONATE<img alt='' src={HeartIcon} draggable='false' style={{
                                    paddingLeft: 10
                                }}></img>
                                </button>
                            </div>
                            <hr size='1' color={SquordleColors.squordleLightGray} style={{ margin: 20, marginTop: 40 }}></hr>
                            <p style={{
                                marginLeft: 20,
                                marginRight: 20,
                                marginTop: 40,
                                fontWeight: 500
                            }}><b>SQUORDLE</b> is free to play, and that is how it shall stay.</p>
                            <p style={styles.ruleText}>But with more and more players, we are itching to bring you more and more features!</p>
                            <p style={styles.ruleText}>Achievements, multiplayer, and perhaps even competitions may be coming soon, but only with <b>your support!</b></p>
                            <p style={styles.ruleText}>Sincerely,</p>
                            <p style={styles.ruleText}>Sickk Software Team</p>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        )

    }

}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        zIndex: 1000,
        backgroundColor: 'white',
        position: 'absolute'
    },
    button: {
        backgroundColor: 'transparent',
        right: 0,
        aspectRatio: 0.7,
        height: '100%',
        display: 'flex',
        borderWidth: 0,
        borderRadius: 4,
        cursor: 'pointer',
        alignContent: 'center',
        justifyContent: 'center'
    },
    icon: {
        height: 60,
        width: '3vh',
        margin: 'auto',
    },
    ruleText: {
        margin: 20
    },
    sickk: {
        fontFamily: 'Play',
        margin: 20,
        textAlign: 'center',
        color: SquordleColors.squordleGray
    }
}
import React, { Component } from "react";
import { SquordleColors } from "../styles/squordleStyles";
import CloseIcon from '../assets/close-icon.svg';
import SickkAttribution from '../assets/sickk-attribution.svg';
import BoardExample from '../assets/board-example-svg.svg';
import KeyboardExample from '../assets/keyboard-example.svg';
import { CSSTransition } from "react-transition-group";

export default class Rules extends Component {

    render() {

        let show = this.props.show
        const closeRules = this.props.onClose

        return (
            <CSSTransition
                // nodeRef={nodeRef}
                in={show}
                timeout={300}
                classNames="alert"
                unmountOnExit
            // onExited={() => onExit()}
            >
                <div style={{
                    ...styles.container,
                }}>
                    <div style={{
                        height: 'auto',
                        width: '100%',
                        position: 'relative',
                        maxWidth: 600
                    }}>
                        <div style={{
                            height: 80,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}>
                            <p style={{
                                textAlign: 'center',
                                fontSize: '3vh',
                                margin: 0,
                                flex: 1,
                                paddingLeft: 70,
                            }}>RULES</p>
                            <button onClick={() => {
                                closeRules()
                            }} style={styles.button}>
                                <img alt='' src={CloseIcon} draggable='false' style={styles.icon}></img>
                            </button>
                        </div>
                        <div style={{
                            width: '100%',
                            height: 'auto',
                            fontWeight: 400,
                        }}>
                            <div style={{
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                marginBottom: 40
                            }}>
                                <a target='_blank' rel='noopener noreferrer' href="https://www.sickksoftware.com"><img alt='' src={SickkAttribution} draggable='false' style={{
                                    width: 'auto',
                                    maxWidth: '100%',
                                    opacity: 0.4
                                }}></img></a>
                                {/* <p style={{
                                margin: 0,
                                marginTop: 10,
                                opacity: 0.4
                            }}>Based on <a href='https://www.nytimes.com/games/wordle/index.html'>WORDLE</a></p> */}
                            </div>
                            {/* <p style={{
                                marginLeft: 20,
                                marginRight: 20,
                                marginTop: 20,
                                fontWeight: 500
                            }}>Complete the <b>SQUORDLE</b> board before running out of guesses!</p> */}
                            <p style={styles.ruleText}>Use letter and word guesses to solve all 5 words on the <b>SQUORDLE</b> board!</p>
                            <p style={styles.ruleText}>Each board will start with <b>5</b> letters pre-revealed for you.</p>
                            <p style={styles.ruleText}>Players have a maximum of <b>8</b> letter guesses, and <b>5</b> incorrect word guesses (whiffs).</p>
                            <p style={styles.ruleText}>Use <b>letter</b> guesses to partially reveal words, and <b>word</b> guesses to solve complete words. The game is over when the board is completed, OR the player uses all 5 whiffs!</p>
                            <p style={styles.ruleText}><b>WHIFFS</b> will turn gold if they contain <b>unguessed</b> letters that have yet to be revealed in the board.</p>
                            <p style={styles.ruleText}><b>CORRECT WORD</b> guesses will highlight keyboard letters that are still in the board that have <b>not</b> been guessed yet.</p>
                            {/* Each game starts with <b>1</b> letter already revealed for you.  */}
                            {/* <p style={{
                                margin: 20,
                                marginBottom: 40
                            }}>After each guess, the color of your game and keyboard will change to show your progress!</p> */}
                            <div style={styles.colorItem}>
                                <div style={{
                                    ...styles.box,
                                    backgroundColor: SquordleColors.squordleGreen,
                                }}>S</div>
                                <p style={{ ...styles.ruleText }}>Revealed letters are <b>green</b></p>
                            </div>
                            <div style={styles.colorItem}>
                                <div style={{
                                    ...styles.box,
                                    backgroundColor: SquordleColors.squordleGold,
                                }}>T</div>
                                <p style={styles.ruleText}>Letters still hidden are <b>gold</b></p>
                            </div>
                            <div style={styles.colorItem}>
                                <div style={{
                                    ...styles.box,
                                    backgroundColor: SquordleColors.squordleMiss,
                                }}>Q</div>
                                <p style={styles.ruleText}>Missed words & letters are <b>gray</b></p>
                            </div>
                            <hr size='1' color={SquordleColors.squordleLightGray} style={{ margin: 20 }}></hr>
                            <p style={styles.ruleText}><b>Examples</b></p>
                            <img alt='' src={BoardExample} draggable='false' style={{
                                width: 'auto',
                                maxWidth: '100%',
                                marginLeft: 20,
                                marginRight: 20,
                            }}></img>
                            <p style={styles.ruleText}>This board started with the letter <b>A</b> revealed.</p>
                            <p style={styles.ruleText}>The letters <b>S and C</b> were guessed and revealed.</p>
                            <p style={styles.ruleText}>The letter <b>F</b> was guessed but missed.</p>
                            <p style={styles.ruleText}><b>SCRAP</b> was guessed and was a whiff, but contained <b>unrevealed</b> letters.</p>
                            <p style={styles.ruleText}><b>AMUSE</b> was a whiff, and it contained <b>no</b> new letters (M, U, and E must not appear in the board!)</p>
                            <p style={styles.ruleText}><b>SCRAT</b> was <b>correctly</b> guessed and it highlighted <b>T</b> on the keyboard! (as shown below). This means that there are more <b>T and R</b> squares to be revealed!</p>
                            <img alt='' src={KeyboardExample} draggable='false' style={{
                                width: 'auto',
                                maxWidth: '100%',
                                marginTop: 20,
                                marginLeft: 20,
                                marginRight: 20,
                            }}></img>
                            <hr size='1' color={SquordleColors.squordleLightGray} style={{ margin: 20, marginTop: 40 }}></hr>
                            <p style={styles.ruleText}><b>A new SQUORDLE will be available each day!</b></p>
                            <hr size='1' color='white' style={{ margin: 20, marginTop: 100 }}></hr>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        )

    }

}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        zIndex: 1000,
        backgroundColor: 'white',
        position: 'absolute'
    },
    button: {
        backgroundColor: 'transparent',
        right: 0,
        aspectRatio: 0.7,
        height: '100%',
        display: 'flex',
        borderWidth: 0,
        borderRadius: 4,
        cursor: 'pointer',
        alignContent: 'center',
        justifyContent: 'center'
    },
    icon: {
        height: 60,
        width: '3vh',
        margin: 'auto',
    },
    ruleText: {
        margin: 20,
    },
    sickk: {
        fontFamily: 'Play',
        margin: 20,
        textAlign: 'center',
        color: SquordleColors.squordleGray
    },
    colorItem: {
        height: '6vh',
        width: '100%',
        flex: 1,
        aspectRatio: 1,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    box: {
        height: '100%',
        aspectRatio: 1,
        widows: '100%',
        fontWeight: 600,
        color: 'white',
        display: 'flex',
        fontSize: '4vh',
        justifyContent: 'center', alignItems: 'center'
    }
}





// Complete the SQUORDLE board before running out of guesses!

// You get 8 letter guesses and 5 whiffs (missed word guesses) to solve all 5 words. (words go left to right)

// Each board starts with 1 letter revealed for you.

// CORRECT WORD guesses do not count against you. They will also hightlight keyboard letters that are still in the board that have not been guessed yet.

// After each guess, the color of your game and keyboard will change to show your progress!
import React, { useRef, useState } from "react";
import { SquordleColors } from "../styles/squordleStyles";
import '../styles/animations.css'
import { CSSTransition } from "react-transition-group";

export default function Alert({ message }) {

    const [show, setShow] = useState(false)
    const nodeRef = useRef(null)

    setTimeout(() => {
        setShow(true)
    }, 200);

    let timestamp = new Date()

    return (
        <div key={'alert:' + timestamp.toString} style={{
            paddingTop: 20
        }}>
            <CSSTransition
                key={'trans:' + timestamp.toString}
                nodeRef={nodeRef}
                in={show}
                timeout={{
                    enter: 200,
                    exit: 1500
                }}
                classNames='alert'
                unmountOnExit
            >
                <div ref={nodeRef} style={{
                    backgroundColor: SquordleColors.squordleAlertBackground,
                    width: 'auto',
                    height: 60,
                    paddingLeft: 25,
                    paddingRight: 25,
                    zIndex: 10000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    borderRadius: 4,
                    fontSize: '2vh',
                }}>{message}</div>
            </CSSTransition>
        </div>
    )

}

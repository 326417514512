import React from "react";
import { SquordleStyles } from "../styles/squordleStyles";
import GridSquare from "./custom/GridSquare";
import GameMaster from "./objects/GameMaster";
import Puzzle from "./objects/Puzzle";
import { LETTER_STATE } from "./objects/SquareGuess";


export default class Board extends React.Component {

    render() {

        const squares = this.props.squares
        const newSquares = this.props.newSquares
        const wordAnimation = this.props.wordAnimation
        const initialAnimations = this.props.initialAnimations


        let g = []

        squares.forEach(a_r => {
            let r = squares.indexOf(a_r)
            a_r.forEach(LG => {
                let i = a_r.indexOf(LG)
                g.push(<GridSquare key={r * 5 + i} style={styleForI(r, i)} letter={lForI(r, i)} animation={animateForI(r, i)} isRL={isRL(r, i)}></GridSquare>)
            })
        })

        function styleForI(r, i) {
            if (squares.length === 0) return SquordleStyles.boxEmpty
            let row = squares[r]
            let S = row[i]
            if (S.state === LETTER_STATE.CORRECT) return SquordleStyles.boxCorrect
            else if (S.state === LETTER_STATE.MISS) return SquordleStyles.boxMiss
            return SquordleStyles.boxEmpty
        }

        function lForI(r, i) {
            if (squares.length === 0) return ''
            let row = squares[r]
            let S = row[i]
            if (S.state !== LETTER_STATE.EMPTY) return S.value
            return ''
        }

        function isRL(r, i) {
            if (squares.length === 0) return false
            let row = squares[r]
            let S = row[i]
            if (GameMaster.getInstance().currentPuzzle().revealLetters.includes(S.value.toLowerCase())) {
                return true
            } else return false
        }

        function animateForI(r, i) {
            let a = { a: false, d: 0, w: wordAnimation }
            if (initialAnimations.length > 0) {
                initialAnimations.forEach(i_a => { if (i_a.r === r && i_a.c === i) a = { a: true, d: (r * 5 + i) * 25 + 500, w: wordAnimation } })
            } else {
                if (squares.length === 0 || newSquares.length === 0) return { a: false, d: 0, w: wordAnimation }
                newSquares.forEach(NS => {
                    if (NS.r === r && NS.c === i) {
                        a = { a: true, d: newSquares.indexOf(NS) * 100 + 75, w: wordAnimation }
                    }
                })
            }
            return a
        }

        return (
            <div style={styles.board}>
                <p style={styles.title}>BOARD</p>
                <div style={{
                    aspectRatio: 1,
                }}>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(5, calc((100% - 8px)/5))',
                        gridTemplateRows: 'repeat(5, calc((100% - 8px)/5))',
                        gap: 2,
                    }}>
                        {g}
                    </div>
                </div>
            </div>
        )

    }

}


const styles = {
    board: {
        flex: 'auto',
        minWidth: 'calc(5*(100% - 14px - 4px)/8)',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 0,
    },
    title: {
        marginTop: 15,
        marginBottom: 5,
        fontSize: '2vh'
    }
}


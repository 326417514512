import React, { Component } from "react";
import { SquordleColors, SquordleStyles } from "../styles/squordleStyles";
import '../styles/animations.css'
import { CSSTransition } from "react-transition-group";
import Puzzle from "./objects/Puzzle";
import GameMaster from "./objects/GameMaster";
import { LETTER_STATE } from "./objects/SquareGuess";


const RESULT = {
    PROGRESS: 1,
    WIN: 2,
    LOST: 3
}


export default class GameResultAlert extends Component {

    render() {

        let message = this.props.message
        let score = this.props.score
        let result = this.props.result
        let show = this.props.show

        const onExit = this.props.onExit

        let color = result === RESULT.WIN ? SquordleColors.squordleGold : SquordleColors.squordleAlertBackground

        let g = []
        let gm = GameMaster.getInstance()
        let p = gm.currentPuzzle()

        p.words.forEach(word => {
            let ro = p.words.indexOf(word)
            let c_list = word.split('')
            c_list.forEach(c => {
                let co = c_list.indexOf(c)
                g.push(<div key={ro * 5 + co} style={bs(ro, co)}>{c.toUpperCase()}</div>)
            })
        })

        function bs(r, c) {
            let lg = gm.boardSquares[r][c]
            if (lg.state === LETTER_STATE.CORRECT) return SquordleStyles.gameLostBoxCorrect
            return SquordleStyles.gameLostBoxMiss
        }

        return (
            <CSSTransition
                // nodeRef={nodeRef}
                in={show}
                timeout={300}
                classNames="alert"
                unmountOnExit
                onExited={() => onExit()}
            >
                <div id="gameResultContainer" key='gameResultAlert' style={{
                    backgroundColor: SquordleColors.squordleDimView,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    zIndex: 1000,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 0,
                    position: 'absolute',
                    pointerEvents: show ? 'all' : 'none'
                }}>
                    {result === RESULT.WIN ?
                        <div ref={null} style={{
                            backgroundColor: color,
                            width: 220,
                            height: 'auto',
                            marginBottom: '45vh',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            color: 'white',
                            paddingBottom: 12,
                            paddingTop: 12,
                            borderRadius: 4,
                            fontSize: '2.3vh',
                        }}>
                            <p style={{
                                margin: 0,
                                fontSize: '4.2vh'
                            }}>{score}</p>
                            <p style={{
                                margin: 0
                            }}>{message}</p>
                        </div>
                        :
                        <div style={{
                            width: 'auto',
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 4,
                            padding: 20,
                            backgroundColor: SquordleColors.squordleAlertBackground,
                        }}>
                            <p style={{
                                color: 'white',
                                textAlign: 'center',
                                marginBottom: 30,
                                marginTop: 10,
                                fontSize: '2.8vh'
                            }}>Nice try!</p>
                            <div style={{
                                display: 'grid',
                                width: 'auto',
                                aspectRatio: 1,
                                gridTemplateColumns: 'repeat(5, 5.5vh)',
                                gridTemplateRows: 'repeat(5, 5.5vh)',
                                gap: 0,
                            }}>
                                {g}
                            </div>
                        </div>

                    }
                </div>
            </CSSTransition>

        )

    }

}

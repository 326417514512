import Cookies from 'universal-cookie';
import GameMaster, { GAME_MODE, GAME_STATE } from '../components/objects/GameMaster';
import Puzzle from '../components/objects/Puzzle';

const cookies = new Cookies();
const puzzle = Puzzle.getInstance()

const storage = window.localStorage

export default class memory {

    static myInstance = null;

    /**
 * @returns {memory}
 */
    static getInstance() {
        if (memory.myInstance == null) {
            memory.myInstance = new memory();
        }
        // testing
        return this.myInstance;

    }


    /** @return {boolean} g - The game master to update from */
    didShowRulesOnFirstLoad() {
        let s = storage.getItem('didShowRulesOnFirstLoad')
        if (s !== 'true') storage.setItem('didShowRulesOnFirstLoad', 'true')
        return s === 'true' ? true : false
    }


    /** @return {boolean} g - The game master to update from */
    fireTimeLoadGameForDate(d) {
        let r = JSON.parse(storage.getItem('plays'))
        let s = true
        if (r === null) {
            let n = {}
            n[d] = true
            storage.setItem('plays', JSON.stringify(n))
        }
        else {
            let dp = r[d]
            if (dp === undefined) {
                r[d] = true
                storage.setItem('plays', JSON.stringify(r))
            } else s = false
        }
        return s
    }



    loadGameMode() {
        const m = storage.getItem('mode')
        if (m === null) return GAME_MODE.DAILY
        return m === 'daily' ? GAME_MODE.DAILY : GAME_MODE.PRACTICE
    }

    saveGameMode(m) {
        storage.setItem('mode', m === GAME_MODE.DAILY ? 'daily' : 'practice')
    }



    /** @return {GameMaster} g - The game master to update from */
    loadForGame(date) {
        // let g = cookies.get(date)
        let g = JSON.parse(storage.getItem(date))
        return g
    }

    /** @param {GameMaster} g - The game master to update from */
    update(g) {
        storage.setItem(g.currentPuzzle().date, JSON.stringify(g))
    }



    loadCurrentPracticeGame() {
        let g = JSON.parse(storage.getItem('practice'))
        return g
    }

    /** @return {Puzzle} p - The puzzle currently playing for practice */
    loadCurrentPracticePuzzle() {
        let p = JSON.parse(storage.getItem('practice_puzzle'))
        return p
    }

    /** @param {Puzzle} g - The game master to update from */
    savePracticePuzzle(p) {
        storage.setItem('practice_puzzle', JSON.stringify(p))
    }

    /** @param {GameMaster} g - The game master to update from */
    updatePractice(g) {
        storage.setItem('practice', JSON.stringify(g))
    }




    finishedGame(r, s) {
        let k = 'practice_stats'
        if (GameMaster.getInstance().isDaily()) k = 'stats'
        let p = JSON.parse(storage.getItem(k))
        if (p === null) p = []
        p.push({ date: puzzle.date, r: r, s: s })
        storage.setItem(k, JSON.stringify(p))
    }


    stats() {

        // check for cookie conversions
        let ck = cookies.get('stats')
        if (ck !== undefined) {
            let prevDates = []
            ck.forEach(g => {
                prevDates.push(g)
            })
            let ls = JSON.parse(storage.getItem('stats'))
            if (ls === null) ls = prevDates
            else ls = ls.concat(prevDates)
            storage.setItem('stats', JSON.stringify(ls))
        }

        cookies.remove('stats')


        let k = 'practice_stats'
        if (GameMaster.getInstance().isDaily()) k = 'stats'
        const s = JSON.parse(storage.getItem(k))
        var scores = {}
        var wins = 0
        var streaks = []
        let prevd = ''

        if (s === null) return { s: { played: 0, winP: 0, streak: 0, max: 0 }, g: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }

        s.forEach(game => {

            let i = s.indexOf(game)
            let pc = scores[game.s]
            if (pc === undefined || isNaN(pc)) pc = 0
            if (game.r === GAME_STATE.WON) scores[game.s] = pc + 1
            if (game.r === GAME_STATE.WON) wins++

            if (i === 0) {
                prevd = game.date
                streaks = [1]
            }
            else {
                let isOnStreak = isOneDayLater(prevd, game.date)
                if (isOnStreak) {
                    let currentS = streaks[streaks.length - 1]
                    streaks[streaks.length - 1] = currentS + 1
                }
                else streaks.push(1)
                prevd = game.date
            }

        })

        let wp = Math.round((wins / s.length) * 100)
        if (wins === 0) wp = 0

        let stats = {
            played: s.length,
            winP: wp,
            streak: streaks[streaks.length - 1],
            max: Math.max(...streaks)
        }

        let graph = []
        for (let index = 0; index < 14; index++) {
            let i = scores[index] ? scores[index] : 0
            graph.push(i)
        }

        return { s: stats, g: graph }

    }

}

function isOneDayLater(prev, day) {
    let p = Date.parse(prev)
    let d = Date.parse(day)
    return d - p <= 24 * 60 * 60 * 1000
}


function cookie_opt() {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 1)
    return {
        expires: date
    }
}
import React, { Component } from "react";
import { SquordleColors } from "../styles/squordleStyles";
import CloseIcon from '../assets/close-icon.svg';
import SquordleLogo from '../assets/squordle-logo.svg';
import { CSSTransition } from "react-transition-group";

export default class RulesSummary extends Component {

    render() {

        let show = this.props.show
        const closeRules = this.props.onClose

        return (
            <CSSTransition
                // nodeRef={nodeRef}
                in={show}
                timeout={300}
                classNames="alert"
                unmountOnExit
            // onExited={() => onExit()}
            >
                <div style={styles.container}>
                    <div style={{
                        height: 'auto',
                        width: '90%',
                        position: 'relative',
                        maxWidth: 500,
                        backgroundColor: 'white',
                        margin: 'auto',
                        borderRadius: 4,
                        flexDirection: 'column',
                        boxShadow: '0px 5px 60px #B8BABF'
                    }}>
                        <button onClick={() => closeRules()} style={styles.button}>
                            <img alt='' src={CloseIcon} draggable='false' style={styles.icon}></img>
                        </button>
                        <div style={styles.centerContainer}>
                            <img alt='' src={SquordleLogo} draggable='false' style={{
                                height: 60 * 0.7,
                                width: 60 * 0.7,
                                margin: 0,
                            }}></img>
                        </div>
                        <p style={styles.titleText}>Welcome to SQUORDLE!</p>
                        {/* <p style={styles.ruleText}>Complete the board before running out of guesses!</p>
                        <p style={styles.ruleText}>You get <b>8 letter</b> guesses and <b>5 whiffs</b> (missed word guesses) to solve all 5 words (words go left to right)</p> */}
                        <p style={styles.ruleText}>Use letter and word guesses to solve all 5 words on the <b>SQUORDLE</b> board!</p>
                        <p style={styles.ruleText}>Players have a maximum of <b>8</b> letter guesses, and <b>5</b> incorrect word guesses (whiffs).</p>
                        <p style={styles.ruleText}>Use <b>letter</b> guesses to partially reveal words, and <b>word</b> guesses to solve complete words. The game is over when the board is completed, OR the player uses all 5 whiffs!</p>
                        <p style={styles.ruleText}>For more detailed rules, tap the <b>?</b> on the top left of the top bar!</p>
                        <hr size='1' color={SquordleColors.squordleLightGray} style={{ margin: 20 }}></hr>
                        <div style={styles.colorItem}>
                            <div style={{
                                ...styles.box,
                                backgroundColor: SquordleColors.squordleGreen,
                            }}>S</div>
                            <div style={styles.ruleText2}>Revealed letters are <b>green</b></div>
                        </div>
                        <div style={styles.colorItem}>
                            <div style={{
                                ...styles.box,
                                backgroundColor: SquordleColors.squordleGold,
                            }}>T</div>
                            <div style={styles.ruleText2}>Letters still hidden are <b>gold</b></div>
                        </div>
                        <div style={styles.colorItem}>
                            <div style={{
                                ...styles.box,
                                backgroundColor: SquordleColors.squordleMiss,
                            }}>Q</div>
                            <div style={styles.ruleText2}>Missed words & letters are <b>gray</b></div>
                        </div>
                        <p style={{
                            ...styles.ruleText,
                            textAlign: 'center'
                        }}>Test your skills and enjoy a new <b>SQUORDLE</b> every day!</p>
                    </div>
                </div>
            </CSSTransition>
        )

    }

}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        zIndex: 1000,
        justifyContent: 'center',
        backgroundColor: SquordleColors.squordleDimView,
        position: 'absolute'
    },
    button: {
        backgroundColor: 'transparent',
        right: 0,
        aspectRatio: 0.7,
        height: 65,
        display: 'flex',
        position: 'absolute',
        borderWidth: 0,
        borderRadius: 4,
        cursor: 'pointer',
        alignContent: 'center',
        justifyContent: 'center'
    },
    centerContainer: {
        display: 'flex',
        paddingTop: 20,
        flex: 'min-content',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        height: 60,
        width: '3vh',
        margin: 'auto',
    },
    titleText: {
        fontSize: '2.5vh',
        fontWeight: 600,
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 30
    },
    ruleText: {
        margin: 20,
        fontWeight: 500
    },
    ruleText2: {
        margin: 20,
        fontWeight: 500,
        flex: 'auto',
        width: 'max-content'
    },
    sickk: {
        fontFamily: 'Play',
        margin: 20,
        textAlign: 'center',
        color: SquordleColors.squordleGray
    },
    colorItem: {
        height: '6vh',
        // width: 'fit-content',
        flex: 1,
        aspectRatio: 1,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    box: {
        height: '100%',
        aspectRatio: 1,
        widows: '100%',
        color: 'white',
        display: 'flex',
        fontSize: '4vh',
        justifyContent: 'center', alignItems: 'center'
    }
}
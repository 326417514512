import React from "react";
import { CSSTransition } from "react-transition-group";
import gameStyles from "../styles/gameStyles";
import CloseIcon from '../assets/close-icon.svg';
import StatsView from "./custom/StatsView";
import DistributionView from "./custom/DistributionView";
import NextPuzzleView from "./custom/NextPuzzleView";
import ShareView from "./custom/ShareView";
import GameMaster from "./objects/GameMaster";
import HeartIcon from '../assets/heart-filled-icon.svg';
import { SquordleColors } from "../styles/squordleStyles";
import { FirebaseEvent } from "../utils/firebase";


export default class GameSummary extends React.Component {

    render() {

        let show = this.props.show
        const closeSummary = this.props.onClose
        const shareClicked = this.props.onShare


        function didClickScreen(e) {
            const id = e.target.id
            if (id === 'screen') closeSummary()
        }

        let todaysGamePlayed = GameMaster.getInstance().state > 1

        return (
            <CSSTransition
                // nodeRef={nodeRef}
                in={show}
                timeout={300}
                classNames="alert"
                unmountOnExit
            // onExited={() => onExit()}
            >
                <div id='screen' onClick={(e) => didClickScreen(e)} style={gameStyles.gameFinishedContainer}>
                    <div id='menu' style={{
                        ...styles.menu,
                        height: todaysGamePlayed ? '80%' : '60%',
                    }}>
                        <button onClick={() => closeSummary()} style={styles.button}>
                            <img alt='' src={CloseIcon} draggable='false' style={styles.icon}></img>
                        </button>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            position: 'relative',
                            marginTop: '6%',
                            marginBottom: '3%',
                        }}>
                            <p style={styles.title}>{GameMaster.getInstance().isDaily() ? 'STATS' : 'PRACTICE'}</p>
                        </div>
                        <div style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <StatsView />
                            <button onClick={() => {
                                FirebaseEvent('clickedDonate', {})
                                window.open('https://www.buymeacoffee.com/squordle', '_blank')
                            }} style={{
                                height: 50,
                                marginTop: 20,
                                width: '30%',
                                minWidth: 150,
                                borderWidth: 0,
                                borderRadius: 4,
                                backgroundColor: SquordleColors.squordleGold,
                                color: 'white',
                                alignSelf: 'center',
                                fontSize: '1.9vh',
                                cursor: 'pointer',
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                            }}>DONATE<img alt='' src={HeartIcon} draggable='false' style={{
                                paddingLeft: 10
                            }}></img>
                            </button>
                            <DistributionView load={show} />
                            {todaysGamePlayed ?
                                <div style={{
                                    flex: 0.2,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: 10
                                }}>
                                    <NextPuzzleView />
                                    <ShareView didClickShare={() => shareClicked()} />
                                </div>
                                :
                                null}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        )

    }

}

const styles = {
    menu: {
        backgroundColor: 'white',
        width: '90%',
        position: 'relative',
        maxWidth: 500,
        display: 'flex',
        borderRadius: 4,
        flexDirection: 'column',
        boxShadow: '0px 5px 60px #B8BABF'
    },
    title: {
        flex: 1,
        // marginLeft: '10vh',
        margin: 0,
        width: '50%',
        textAlign: 'center',
        fontSize: '2vh',
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    button: {
        backgroundColor: 'transparent',
        right: 0,
        position: 'absolute',
        zIndex: 1000,
        width: '10vh',
        height: '10vh',
        display: 'flex',
        borderWidth: 0,
        borderRadius: 4,
        cursor: 'pointer',
        alignContent: 'center',
        justifyContent: 'center'
    },
    icon: {
        height: '60%',
        width: '3vh',
        margin: 'auto',
    },
}

import firebase from 'firebase/compat/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { GAME_STATE } from '../components/objects/GameMaster';

const firebaseConfig = {
    apiKey: "AIzaSyAEbnVfZRfYniiAs3l3Ksi84gor9elOutE",
    authDomain: "squordle-6a132.firebaseapp.com",
    projectId: "squordle-6a132",
    storageBucket: "squordle-6a132.appspot.com",
    messagingSenderId: "438296329514",
    appId: "1:438296329514:web:1c957670111bade00d3253",
    measurementId: "G-9XTH1JCPYP"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore()
export const analytics = getAnalytics(app)


export function puzzlesCollection() {
    return db.collection('puzzleWords')
}


export function FirebaseEvent(eventName, parameters) {
    logEvent(analytics, eventName, parameters)
}


export function newPlay() {
    const functions = getFunctions()
    const call = httpsCallable(functions, 'didStartGame')
    call({}).then(result => {
    }).catch(err => console.log('error updating: ', err))
}

/** @param {GameMaster} g - The current game */

export function completedGame(g) {
    const functions = getFunctions()
    const call = httpsCallable(functions, 'updateGames')
    call({
        win: g.state === GAME_STATE.WON,
        score: g.getScore(),
        letters: g.letterSquares.length,
        whiffs: g.whiffs.length
    }).then(result => {
    }).catch(err => console.log('error updating: ', err))
}


export default db
import React, { Component } from "react"

export default class StatsCell extends Component {

    render() {

        let v = this.props.value
        let l = this.props.label

        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 0,
                minWidth: 50,
                marginLeft: '5%',
                marginRight: '5%'
            }}>
                <p style={{
                    textAlign: 'center',
                    fontSize: '4.5vh',
                    margin: 0,
                    fontWeight: 500,
                    paddingBottom: 3
                }}>{v}</p>
                <p style={{
                    textAlign: 'center',
                    fontSize: '1.4vh',
                    margin: 0,
                    fontWeight: 600,
                    textTransform: 'uppercase'
                }}>{l}</p>
            </div>
        )

    }

}
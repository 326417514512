import React from "react";
import { SquordleStyles } from "../styles/squordleStyles";
import GridSquare from "./custom/GridSquare";
import { LETTER_STATE } from "./objects/SquareGuess";

export default class Letters extends React.Component {

    render() {

        const letters = this.props.letters
        const newLetters = this.props.newLetters

        function styleForI(i) {
            let s = { style: SquordleStyles.boxEmpty, miss: false }
            letters.forEach(LG => {
                let x = letters.indexOf(LG)
                if (i === x) {
                    if (LG.state === LETTER_STATE.CORRECT) s = { style: SquordleStyles.boxCorrect, miss: false }
                    else if (LG.state === LETTER_STATE.MISS) s = { style: SquordleStyles.boxMiss, miss: true }
                }
            })
            return s
        }

        function lForI(i) {
            let l = ''
            letters.forEach(LG => {
                let x = letters.indexOf(LG)
                if (i === x) {
                    if (LG.state !== LETTER_STATE.EMPTY) l = LG.value
                }
            })
            return l
        }

        function animateForI(i) {
            if (letters.length === 0 || newLetters.length === 0) return { a: false, d: 0 }
            let a = { a: false, d: 0 }
            if (newLetters.length > 0) {
                if (i === (letters.length - 1)) a = { a: true, d: 0 }
                // letters.forEach(LG => {
                //     let x = letters.indexOf(LG)
                //     if (x === i) a = { a: LG.value !== LETTER_STATE.CORRECT, d: 0 }
                // })
            }
            return a
        }



        return (
            <div style={styles.letters}>
                <div style={{
                    width: '100%',
                    height: 'auto',
                }}>
                    <p style={styles.title}>LETTERS</p>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(8, calc((100% - 2*7px)/8))',
                        gridTemplateRows: 'repeat(1, calc((100% - 2*7px)/8))',
                        gap: 2,
                        paddingLeft: 10,
                        marginRight: 10,
                        marginBottom: 10,
                        flex: 1,
                        // transform: 'scaleY(0.98)',
                        transformOrigin: '50% top'
                    }}>
                        <GridSquare key={0} style={styleForI(0).style} miss={styleForI(0).miss} letter={lForI(0)} animation={animateForI(0)} />
                        <GridSquare key={1} style={styleForI(1).style} miss={styleForI(1).miss} letter={lForI(1)} animation={animateForI(1)} />
                        <GridSquare key={2} style={styleForI(2).style} miss={styleForI(2).miss} letter={lForI(2)} animation={animateForI(2)} />
                        <GridSquare key={3} style={styleForI(3).style} miss={styleForI(3).miss} letter={lForI(3)} animation={animateForI(3)} />
                        <GridSquare key={4} style={styleForI(4).style} miss={styleForI(4).miss} letter={lForI(4)} animation={animateForI(4)} />
                        <GridSquare key={5} style={styleForI(5).style} miss={styleForI(5).miss} letter={lForI(5)} animation={animateForI(5)} />
                        <GridSquare key={6} style={styleForI(6).style} miss={styleForI(6).miss} letter={lForI(6)} animation={animateForI(6)} />
                        <GridSquare key={7} style={styleForI(7).style} miss={styleForI(7).miss} letter={lForI(7)} animation={animateForI(7)} />
                    </div>
                </div>
            </div>
        )

    }

}


const styles = {
    letters: {
        flex: 0.6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        marginLeft: 10,
        marginTop: 0,
        marginBottom: 5,
        fontSize: '2vh'
    },
}


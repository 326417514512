export const SquordleColors = {
    squordleGreen: '#6aaa64',
    squordleDarkGreen: '#538d4e',
    squordleGray: '#9C9EA4',
    squordleLightGray: '#CFD1D5',
    squordleVeryLightGray: '#EBEBEE',
    squordleKeyboardGray: '#E0E3E7',
    squordleEmpty: '#D2D6D9',
    squordleMiss: '#575963',
    squordlePlaceholder: '#C1C3C5',
    squordleLightBlue: '#B1C5FF',
    squordleBlue: '#6E83FF',
    squordleAlertBackground: '#252528',
    squordleGold: '#c9b458',
    squordleRed: '#CE5D5D',
    squordleDimView: 'rgba(255, 255, 255, 0.7)',
    squordleLightDimView: 'rgba(255, 255, 255, 0.5)',
}

export const SquordleStyles = {
    boxEmpty: {
        aspectRatio: 1,
        textAlign: 'center',
        // justifyContent: 'center',
        // alignItems: 'center',
        position: 'relative',
        display: 'flex',
        margin: 0,
        padding: 0,
        lineHeight: 0,
        fontSize: '4vh',
        borderWidth: '0.3vh',
        borderColor: SquordleColors.squordleLightGray,
        borderStyle: 'solid'
    },
    boxEmptyLastOne: {
        aspectRatio: 1,
        textAlign: 'center',
        // justifyContent: 'center',
        // alignItems: 'center',
        position: 'relative',
        display: 'flex',
        margin: 0,
        padding: 0,
        lineHeight: 0,
        fontSize: '4vh',
        borderWidth: '0.3vh',
        borderColor: SquordleColors.squordleRed,
        borderStyle: 'solid'
    },
    boxCorrect: {
        aspectRatio: 1,
        justifyContent: 'center',
        position: 'relative',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleGreen,
        borderWidth: '0.3vh',
        borderColor: SquordleColors.squordleGreen,
        borderStyle: 'solid',
        display: 'flex',
        margin: 0,
        padding: 0,
        lineHeight: 0,
        fontSize: '4vh',
        color: 'white'
    },
    boxMiss: {
        aspectRatio: 1,
        justifyContent: 'center',
        position: 'relative',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleMiss,
        borderWidth: '0.3vh',
        borderColor: SquordleColors.squordleMiss,
        borderStyle: 'solid',
        display: 'flex',
        margin: 0,
        padding: 0,
        lineHeight: 0,
        fontSize: '4vh',
        color: 'white'
    },
    gameLostBoxMiss: {
        aspectRatio: 1,
        justifyContent: 'center',
        position: 'relative',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleAlertBackground,
        borderWidth: '0.3vh',
        borderColor: SquordleColors.squordleAlertBackground,
        borderStyle: 'solid',
        display: 'flex',
        margin: 0,
        padding: 0,
        lineHeight: 0,
        fontSize: '3.7vh',
        color: 'white'
    },
    gameLostBoxCorrect: {
        aspectRatio: 1,
        justifyContent: 'center',
        position: 'relative',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleGreen,
        borderWidth: '0.3vh',
        borderColor: SquordleColors.squordleGreen,
        borderStyle: 'solid',
        display: 'flex',
        margin: 0,
        padding: 0,
        lineHeight: 0,
        fontSize: '3.7vh',
        color: 'white'
    },
    whiffEmpty: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        margin: 0,
        padding: 0,
        lineHeight: 0,
        fontSize: '4vh',
        borderWidth: '0.3vh',
        borderColor: SquordleColors.squordleLightGray,
        borderStyle: 'solid'
    },
    whiffMiss: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleMiss,
        borderWidth: '0.3vh',
        borderColor: SquordleColors.squordleMiss,
        borderStyle: 'solid',
        display: 'flex',
        margin: 0,
        padding: 0,
        lineHeight: 0,
        fontSize: '3vh',
        color: 'white'
    },
    whiffReveal: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleGold,
        borderWidth: '0.3vh',
        borderColor: SquordleColors.squordleGold,
        borderStyle: 'solid',
        display: 'flex',
        margin: 0,
        padding: 0,
        lineHeight: 0,
        fontSize: '3vh',
        color: 'white'
    },
    keyEmpty: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleVeryLightGray,
        display: 'flex',
        flex: 1,
        margin: 0,
        borderWidth: 0,
        borderRadius: 4,
        padding: 0,
        lineHeight: 0,
        fontSize: '2.2vh',
        color: 'black',
        cursor: 'pointer'
    },
    keyMiss: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleMiss,
        display: 'flex',
        flex: 1,
        margin: 0,
        borderWidth: 0,
        borderRadius: 4,
        padding: 0,
        lineHeight: 0,
        fontSize: '2.2vh',
        color: 'white',
        cursor: 'pointer'
    },
    keyCorrect: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleGreen,
        display: 'flex',
        flex: 1,
        margin: 0,
        borderWidth: 0,
        borderRadius: 4,
        padding: 0,
        lineHeight: 0,
        fontSize: '2.2vh',
        color: 'white',
        cursor: 'pointer'
    },
    keyGold: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleGold,
        display: 'flex',
        flex: 1,
        margin: 0,
        borderWidth: 0,
        borderRadius: 4,
        padding: 0,
        lineHeight: 0,
        fontSize: '2.2vh',
        color: 'white',
        cursor: 'pointer'
    },
    wideKey: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleVeryLightGray,
        display: 'flex',
        width: 'calc(((100% - 70px)/20) + 5px + ((100% - 70px)/10))',
        margin: 0,
        borderWidth: 0,
        borderRadius: 4,
        padding: 0,
        lineHeight: 0,
        fontSize: '1.7vh',
        color: 'black',
        cursor: 'pointer'
    },
    delKey: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: SquordleColors.squordleVeryLightGray,
        display: 'flex',
        width: 'calc(((100% - 70px)/20) + 5px + ((100% - 70px)/10))',
        margin: 0,
        borderWidth: 0,
        borderRadius: 4,
        padding: 0,
        lineHeight: 0,
        fontSize: '1.7vh',
        color: 'black',
        cursor: 'pointer'
    }
}
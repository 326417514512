import React, { Component } from "react";
import Chart from 'chart.js/auto'
import { SquordleColors } from "../../styles/squordleStyles";
import memory from "../../utils/memory";
import GameMaster, { GAME_STATE } from "../objects/GameMaster";


export default class DistributionView extends Component {

    componentDidMount() {
        this.loadData()
    }

    loadData() {

        let game = GameMaster.getInstance()

        let dataSet = memory.getInstance().stats().g
        var colors = dataSet.map((val, i) => ((i) === game.getScore() && game.state === GAME_STATE.WON) ? SquordleColors.squordleGreen : SquordleColors.squordleGray);
        if (game.state === GAME_STATE.IN_PROGRESS) colors = SquordleColors.squordleGray

        // ['13', '12', '11', '10', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0']
        let data = {
            labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
            datasets: [{
                label: 'Score',
                data: dataSet,
                backgroundColor: colors
            }]
        }

        let options = {
            color: 'black',
            datasets: { bar: { minBarLength: 4 } },
            events: 'none',
            plugins: {
                legend: {
                    display: false
                },
            },
            layout: { padding: { top: 20, bottom: 20 } },
            animation: {
                duration: 500,
                delay: 400,
                onComplete: function (c) {
                    var chartInstance = c.chart
                    var ctx = chartInstance.ctx
                    ctx.textAlign = 'center';
                    ctx.fillStyle = "rgba(0, 0, 0, 1)";
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            var data = dataset.data[index];
                            ctx.fillText(data, bar.x, bar.y - 5);
                        });
                    });
                },
            },
            scales: {
                y: { beginAtZero: true, display: false, },
                x: { grid: { display: false, drawBorder: false } }
            }
        }

        const ctx = document.getElementById('pc')
        var myChart = new Chart(ctx, {
            type: 'bar',        // Define chart type
            data: data,    	// Chart data
            options: options
        });

    }

    render() {

        return (

            <div id='distributionView' style={{
                flex: 0.65,
                display: 'flex',
                flexDirection: 'column',
                marginTop: '7%',
                marginRight: '6%',
                marginLeft: '6%'
            }}>
                <p style={styles.title}>PERFORMANCE</p>
                <canvas key='pcGraph' id='pc' style={{
                    width: '80%',
                    height: '80%',
                    paddingTop: '3%'
                }}></canvas>
            </div >

        )

    }

}

const styles = {
    title: {
        flex: 1,
        margin: 0,
        textAlign: 'center',
        fontSize: '2vh',
        fontWeight: 700,
        textTransform: 'uppercase',
    },
}
import React from "react";

var clock_int_id

export default class NextPuzzleView extends React.Component {

    componentWillUnmount() {
        clearInterval(clock_int_id)
    }

    render() {

        function time() {
            let c = document.getElementById('clock')
            if (c !== null) document.getElementById('clock').textContent = n_s()
        }

        clock_int_id = setInterval(time, 1000)

        return (
            <div style={{
                flex: 0.5,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <p style={{
                    fontSize: '1.9vh',
                    margin: 0
                }}>NEXT SQUORDLE</p>
                <p id='clock' style={{
                    fontSize: '3.5vh',
                    margin: 0,
                    paddingTop: 3
                }}>{n_s()}</p>
            </div>
        )

    }

}

function n_s() {
    let d = new Date()
    let h = 23 - d.getHours() < 10 ? '0' + (23 - d.getHours()) : (23 - d.getHours())
    let m = 59 - d.getMinutes() < 10 ? '0' + (59 - d.getMinutes()) : (59 - d.getMinutes())
    let s = 59 - d.getSeconds() < 10 ? '0' + (59 - d.getSeconds()) : (59 - d.getSeconds())
    return h + ':' + m + ':' + s
}
import React from "react";
import { SquordleColors } from "../styles/squordleStyles";
// import CloseIcon from '../assets/close-icon.svg';
// import HeartIcon from '../assets/heart-filled-icon-black.svg';
// import Stats from '../assets/rankings-icon.svg';
import { CSSTransition } from "react-transition-group";
import { GAME_MODE } from "../components/objects/GameMaster";

export default class Menu extends React.Component {

    render() {

        // const closeMenu = this.props.onClose
        const clickDaily = this.props.clickDaily
        const clickPractice = this.props.clickPractice
        const show = this.props.show
        const mode = this.props.mode

        const dc = mode === GAME_MODE.DAILY ? SquordleColors.squordleGreen : 'transparent'
        const pc = mode === GAME_MODE.PRACTICE ? SquordleColors.squordleGreen : 'transparent'
        const dtc = mode === GAME_MODE.DAILY ? 'white' : 'black'
        const ptc = mode === GAME_MODE.PRACTICE ? 'white' : 'black'

        return (
            <CSSTransition
                // nodeRef={nodeRef}
                in={show}
                timeout={300}
                classNames="menu"
                unmountOnExit
            // onExited={() => onExit()}
            >
                <div id="m_screenForM" style={{
                    backgroundColor: SquordleColors.squordleDimView,
                    width: '100%',
                    height: 'calc(100% - 50px)',
                    position: 'absolute',
                    zIndex: 10000,
                    display: "flex",
                    justifyContent: 'center',
                    top: 45,
                    // alignItems: 'center'
                }}>
                    <div style={{
                        backgroundColor: SquordleColors.squordleVeryLightGray,
                        width: '100%',
                        maxWidth: 500,
                        // boxShadow: '0px 5px 60px #B8BABF',
                        height: 50,
                        borderBottomLeftRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <button onClick={() => clickDaily()} style={{ fontSize: '85%', height: '70%', flex: 1, maxWidth: 70, borderRadius: 4, borderWidth: 0, marginRight: 10, marginLeft: 10, backgroundColor: dc, color: dtc, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>DAILY</button>
                        <button onClick={() => clickPractice()} style={{ fontSize: '85%', height: '70%', flex: 1, maxWidth: 100, borderRadius: 4, borderWidth: 0, marginRight: 10, marginLeft: 10, backgroundColor: pc, color: ptc, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>PRACTICE</button>
                    </div>
                </div>
            </CSSTransition>
        )

    }

}


// const styles = {
//     buttonX: {
//         fontSize: 15,
//         height: 60,
//         textAlign: 'right',
//         borderRadius: 4,
//         borderWidth: 0,
//         paddingLeft: 10,
//         backgroundColor: 'transparent',
//         color: 'white',
//         cursor: 'pointer',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'flex-end',
//     },
//     button: {
//         fontSize: 16,
//         height: 50,
//         textAlign: 'right',
//         borderRadius: 4,
//         borderWidth: 0,
//         paddingLeft: 10,
//         paddingRight: 10,
//         backgroundColor: 'transparent',
//         color: 'black',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'flex-end',
//         cursor: 'pointer'
//     },
//     iconX: {
//         height: 35 * 0.7,
//         width: 35 * 0.7,
//         margin: 0,
//         paddingRight: 0
//     },
//     icon: {
//         height: 28 * 0.7,
//         width: 28 * 0.7,
//         margin: 0,
//         marginLeft: 10
//     }
// }
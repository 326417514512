import React from "react";
import { SquordleColors } from "../styles/squordleStyles";


export default class Input extends React.Component {

    render() {

        const currentInput = this.props.input
        const validInput = this.props.validInput

        let userDidInput = currentInput.length > 0

        return (
            <div style={{
                flex: 0.45,
                display: 'flex'
            }}>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    backgroundColor: SquordleColors.squordleVeryLightGray,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 10,
                    marginRight: 10,
                    maxHeight: 100,
                }}>
                    <p style={userDidInput ? (validInput ? styles.validUserInput : styles.invalidUserInput) : styles.placeholder}>{userDidInput ? currentInput : 'Guess a letter OR word'}</p>
                </div>
            </div>
        )

    }

}


const styles = {
    input: {
        flex: 0.4,
        paddingLeft: 10,
        paddingRight: 8,
        backgroundColor: SquordleColors.squordleVeryLightGray,
    },
    validUserInput: {
        textAlign: 'center',
        margin: 0,
        fontSize: 'calc(5vh - 0.05vw)',
        textTransform: 'uppercase'
    },
    invalidUserInput: {
        textAlign: 'center',
        margin: 0,
        fontSize: 'calc(5vh - 0.05vw)',
        color: SquordleColors.squordleRed,
        textTransform: 'uppercase'
    },
    placeholder: {
        textAlign: 'center',
        margin: 0,
        fontSize: '2.7vh',
        textTransform: 'none',
        fontWeight: 700,
        color: SquordleColors.squordleGray
    }
}


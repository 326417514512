import React from "react";
import '../../styles/animations.css'


export default class WhiffSquare extends React.Component {

    render() {

        const word = this.props.word
        const style = this.props.style
        const animation = this.props.animation
        const a = animation.a
        const d = animation.d
        const lw = animation.lw

        let animationId = lw ? 'lastWhiff' : 'whiffBounce'
        let repeats = lw ? 'infinite' : 1
        let dur = lw ? '2s' : '400ms'

        return (
            <div style={{
                ...style,
                animation: a || lw ? (dur + ' cubic-bezier(.04, .58, .32, 1) ' + d + 'ms ' + repeats + ' ' + animationId) : '',
            }}>{word}</div>
        )

    }

}
import React from "react";
import { SquordleStyles } from "../styles/squordleStyles";
import WhiffSquare from "./custom/WhiffSquare";
import { WHIFF_STATE } from "./objects/WhiffGuess";

export default class Misses extends React.Component {

    render() {


        const whiffs = this.props.whiffs
        const newWhiffs = this.props.newWhiffs

        function styleForI(i) {
            let s = SquordleStyles.whiffEmpty
            whiffs.forEach(w => {
                let x = whiffs.indexOf(w)
                if (i === x) s = w.state === WHIFF_STATE.MISS ? SquordleStyles.whiffMiss : SquordleStyles.whiffReveal
            })
            return s
        }


        function wForI(i) {
            let d = ''
            whiffs.forEach(w => {
                let x = whiffs.indexOf(w)
                if (i === x) d = w.value
            })
            return d
        }

        function animateForI(i) {
            if (whiffs.length === 0 || newWhiffs.length === 0) return { a: false, d: 0, lw: (i === 4 && whiffs.length === 4) }
            let a = { a: false, d: 0, lw: (i === 4 && whiffs.length === 4) }
            let w = whiffs[i]
            newWhiffs.forEach(NW => {
                if (w === NW) a = { a: true, d: 100, lw: (i === 4 && whiffs.length === 4) }
            })
            return a
        }


        return (
            <div style={styles.misses}>
                <p style={styles.title}>WHIFFS</p>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(1, auto)',
                    gridTemplateRows: 'repeat(5, auto)',
                    gap: 2,
                    paddingLeft: 10,
                    marginRight: 10,
                    flex: 1
                }}>
                    <WhiffSquare key={0} style={styleForI(0)} word={wForI(0)} animation={animateForI(0)} />
                    <WhiffSquare key={1} style={styleForI(1)} word={wForI(1)} animation={animateForI(1)} />
                    <WhiffSquare key={2} style={styleForI(2)} word={wForI(2)} animation={animateForI(2)} />
                    <WhiffSquare key={3} style={styleForI(3)} word={wForI(3)} animation={animateForI(3)} />
                    <WhiffSquare key={4} style={styleForI(4)} word={wForI(4)} animation={animateForI(4)} />
                </div>
            </div>
        )

    }

}


const styles = {
    misses: {
        flex: 'auto',
        marginLeft: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        marginLeft: 10,
        marginTop: 15,
        marginBottom: 5,
        fontSize: '2vh'
    }
}


import React, { Component } from "react";
import SquordleLogo from '../assets/squordle-logo.svg';
import '../styles/animations.css'

export default class HomeScreen extends Component {

    render() {

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
            }}>
                <img alt="" src={SquordleLogo} draggable='false' style={{
                    height: '10vh',
                    width: '10vh',
                    position: 'absolute',
                    margin: 0,
                    animation: '1s cubic-bezier(.04, .58, .32, 1)  499ms 1 homePageEnter',
                    animationFillMode: 'forwards'
                }}></img>
                <div></div>
            </div>
        )

    }

}
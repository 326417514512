export const LETTER_STATE = {
    EMPTY: 1,
    CORRECT: 2,
    MISS: 3
}

export default class SquareGuess {

    value = ''
    state = LETTER_STATE.EMPTY
    animate = false

    /**
 * @returns {SquareGuess}
 */

    static letterSquare(value) {

        let letterSquare = new SquareGuess()

        letterSquare.value = value
        letterSquare.state = LETTER_STATE.EMPTY

        return letterSquare
    }

    static letterGuess(value, state) {

        let letterSquare = new SquareGuess()

        letterSquare.value = value
        letterSquare.state = state

        return letterSquare
    }

}
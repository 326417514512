import { SquordleColors } from "./squordleStyles"

function adjustForBadAR() {
    let screen = window.screen
    if (screen.height < 700 && screen.width < 390) return '7vw'
    return '0'
}

const styles = {
    screen: {
        display: "flex",
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        flex: 1,
    },
    flexContainer: {
        width: '100%',
        flex: 1,
        display: 'flex',
        margin: 'auto',
    },
    alertContainer: {
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        zIndex: 1000,
        alignItems: 'center',
        flexDirection: 'column',
        gap: 0,
        position: 'absolute'
    },
    gameResultAlertContainer: {
        backgroundColor: SquordleColors.squordleDimView,
        width: '100%',
        height: '100%',
        display: 'flex',
        zIndex: 1000,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 0,
        position: 'absolute'
    },
    gameFinishedContainer: {
        backgroundColor: SquordleColors.squordleDimView,
        width: '100%',
        height: '100%',
        display: 'flex',
        zIndex: 1000,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 0,
        position: 'absolute'
    },
    container: {
        width: '100%',
        maxWidth: 'calc(100vh*0.56)',
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        flex: 1,
        display: 'flex',
        margin: 'auto',
    },
    gameArea: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
        marginRight: adjustForBadAR(),
        marginLeft: adjustForBadAR(),
    },
    boardWhiffArea: {
        display: 'flex',
        flexDirection: 'row',
        height: 'auto',
        marginBottom: 10,
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
        // transform: 'scaleY(0.98)',
        transformOrigin: '50% top'
    },
    lettersInputArea: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    }
}

export default styles
import React from "react";
import { SquordleStyles } from "../styles/squordleStyles";
import { ReactComponent as Logo } from '../assets/delete-icon.svg';
import Puzzle from "./objects/Puzzle";
import { LETTER_STATE } from "./objects/SquareGuess";
import GameMaster from "./objects/GameMaster";


export default class Keyboard extends React.Component {

    render() {

        const onKeyPressCallback = this.props.onKeyPress
        const letterGuesses = this.props.letterGuesses
        const pendingLetters = this.props.pendingLetters
        const burnedLetters = this.props.burnedLetters

        function stateForL(l) {
            let s = SquordleStyles.keyEmpty
            if (GameMaster.getInstance().currentPuzzle().revealLetters.includes(l.toLowerCase())) s = SquordleStyles.keyCorrect
            letterGuesses.forEach(LG => {
                if (LG.value === l) {
                    if (LG.state === LETTER_STATE.CORRECT) s = SquordleStyles.keyCorrect
                    else s = SquordleStyles.keyMiss
                }
            })
            pendingLetters.forEach(p => {
                if (p === l) s = SquordleStyles.keyGold
            })
            burnedLetters.forEach(p => {
                if (p === l) s = SquordleStyles.keyCorrect
            })
            return s
        }


        function kp(k) {
            onKeyPressCallback(k)
        }


        return (
            <div style={styles.keyboardArea}>
                <div style={styles.keyRow}>
                    <button onClick={() => kp({ key: 'Q' })} style={stateForL('Q')}>Q</button>
                    <button onClick={() => kp({ key: 'W' })} style={stateForL('W')}>W</button>
                    <button onClick={() => kp({ key: 'E' })} style={stateForL('E')}>E</button>
                    <button onClick={() => kp({ key: 'R' })} style={stateForL('R')}>R</button>
                    <button onClick={() => kp({ key: 'T' })} style={stateForL('T')}>T</button>
                    <button onClick={() => kp({ key: 'Y' })} style={stateForL('Y')}>Y</button>
                    <button onClick={() => kp({ key: 'U' })} style={stateForL('U')}>U</button>
                    <button onClick={() => kp({ key: 'I' })} style={stateForL('I')}>I</button>
                    <button onClick={() => kp({ key: 'O' })} style={stateForL('O')}>O</button>
                    <button onClick={() => kp({ key: 'P' })} style={stateForL('P')}>P</button>
                </div>
                <div style={styles.keyRow2}>
                    <button onClick={() => kp({ key: 'A' })} style={stateForL('A')}>A</button>
                    <button onClick={() => kp({ key: 'S' })} style={stateForL('S')}>S</button>
                    <button onClick={() => kp({ key: 'D' })} style={stateForL('D')}>D</button>
                    <button onClick={() => kp({ key: 'F' })} style={stateForL('F')}>F</button>
                    <button onClick={() => kp({ key: 'G' })} style={stateForL('G')}>G</button>
                    <button onClick={() => kp({ key: 'H' })} style={stateForL('H')}>H</button>
                    <button onClick={() => kp({ key: 'J' })} style={stateForL('J')}>J</button>
                    <button onClick={() => kp({ key: 'K' })} style={stateForL('K')}>K</button>
                    <button onClick={() => kp({ key: 'L' })} style={stateForL('L')}>L</button>
                </div>
                <div style={styles.keyRow3}>
                    <button onClick={() => kp({ key: 'ENTER' })} style={SquordleStyles.wideKey}>ENTER</button>
                    <button onClick={() => kp({ key: 'Z' })} style={stateForL('Z')}>Z</button>
                    <button onClick={() => kp({ key: 'X' })} style={stateForL('X')}>X</button>
                    <button onClick={() => kp({ key: 'C' })} style={stateForL('C')}>C</button>
                    <button onClick={() => kp({ key: 'V' })} style={stateForL('V')}>V</button>
                    <button onClick={() => kp({ key: 'B' })} style={stateForL('B')}>B</button>
                    <button onClick={() => kp({ key: 'N' })} style={stateForL('N')}>N</button>
                    <button onClick={() => kp({ key: 'M' })} style={stateForL('M')}>M</button>
                    <button onClick={() => kp({ key: 'BACKSPACE' })} style={SquordleStyles.delKey}><Logo className="logo"></Logo></button>
                </div>
            </div>
        )

    }

}


const styles = {
    keyboardArea: {
        width: '100%',
        minHeight: 120,
        height: '22vh',
        borderRadius: 4,
        marginTop: 6,
        bottom: 0,
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    keyRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: 6,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 8,
        marginRight: 8,
        flex: 1,
    },
    keyRow2: {
        display: 'flex',
        flexDirection: 'row',
        gap: 6,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 8,
        marginRight: 8,
        paddingLeft: 'calc(((100% - 70px)/20) + 3px)',
        paddingRight: 'calc(((100% - 70px)/20) + 3px)',
        flex: 1,
    },
    keyRow3: {
        display: 'flex',
        flexDirection: 'row',
        gap: 6,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 8,
        marginRight: 8,
        flex: 1,
    }
}

